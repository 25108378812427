import React from "react"

const HeroSlice = ({ heroData }: any) => {
  return (
    <section
      className="relative bg-cover bg-center bg-no-repeat h-[calc(100vh_-_78px)]"
      style={{ backgroundImage: `url(${heroData.heroImage.url})` }}
    >
      <div className="absolute bottom-[20%] left-0 w-full">
        <div className="container">
          <h1 className="font-bold text-2xl text-white uppercase whitespace-pre-line mb-4 lg:text-4xl lg:mb-7">
            {heroData.title}
          </h1>
          <p className="font-bold text-base text-white uppercase lg:text-xl">
            {heroData.headline}
          </p>
        </div>
      </div>
    </section>
  )
}

export default HeroSlice
