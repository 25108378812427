import * as React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import HeroSlice from "../components/home/hero-slice"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data, intl }) => {
  const heroData = data.allContentfulHeroSlice.nodes[0]

  return (
    <Layout layoutData={data}>
      <Seo title={intl.formatMessage({ id: "home" })} />
      <HeroSlice heroData={heroData} />
    </Layout>
  )
}

export default injectIntl(IndexPage)

export const query = graphql`
  query ContentfulQuery($locale: String) {
    allContentfulHeroSlice(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
        headline
        heroImage {
          url
        }
      }
    }
    allContentfulSubPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        slug
        name
      }
    }
    allContentfulLayout(filter: { node_locale: { eq: $locale } }) {
      nodes {
        logo {
          url
        }
        mobileLogo {
          url
        }
        footerLogo {
          url
        }
        contactMail
        contactSubject
        contactBody {
          contactBody
        }
      }
    }
  }
`
